import * as React from 'react';
import type { SVGProps } from 'react';
function SvgIntersectCircle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 24 24' {...props}>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M9 16A7 7 0 1 0 9 2a7 7 0 0 0 0 14Z'
      />
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M15 22a7 7 0 1 0 0-14 7 7 0 0 0 0 14Z'
      />
    </svg>
  );
}
export default SvgIntersectCircle;
