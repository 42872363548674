import * as React from 'react';
import type { SVGProps } from 'react';
function SvgUsers03(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 24 24' {...props}>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M18 15.837c1.457.731 2.705 1.905 3.616 3.373.18.29.27.436.302.637.063.409-.216.912-.597 1.073-.188.08-.399.08-.82.08M16 11.532a4.5 4.5 0 0 0 0-8.064M14 7.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM2.56 18.938C4.154 16.545 6.67 15 9.5 15c2.831 0 5.347 1.545 6.942 3.938.349.525.523.787.503 1.122-.015.26-.186.58-.395.738-.267.202-.635.202-1.372.202H3.823c-.736 0-1.104 0-1.372-.202a1.109 1.109 0 0 1-.395-.738c-.02-.335.155-.597.504-1.122Z'
      />
    </svg>
  );
}
export default SvgUsers03;
