import * as React from 'react';
import type { SVGProps } from 'react';
function SvgArrowNarrowLeft(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 24 24' {...props}>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M20 12H4m0 0 6 6m-6-6 6-6'
      />
    </svg>
  );
}
export default SvgArrowNarrowLeft;
