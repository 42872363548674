import * as React from 'react';
import type { SVGProps } from 'react';
function SvgLogOut03(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 24 24' {...props}>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='m16 17 5-5m0 0-5-5m5 5H9m3 5c0 .296 0 .443-.011.572a3 3 0 0 1-2.404 2.685 7.012 7.012 0 0 1-.567.074l-1.021.114c-1.535.17-2.302.256-2.911.06a3 3 0 0 1-1.825-1.633C3 18.288 3 17.516 3 15.972V8.028c0-1.544 0-2.316.261-2.9a3 3 0 0 1 1.825-1.633c.61-.195 1.376-.11 2.91.06l1.022.114c.294.032.441.049.567.074a3 3 0 0 1 2.404 2.686C12 6.557 12 6.704 12 7'
      />
    </svg>
  );
}
export default SvgLogOut03;
