import * as React from 'react';
import type { SVGProps } from 'react';
function SvgKota(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 50 38' {...props}>
      <path
        fill='currentColor'
        d='M49.015 3.136c-.05-.45-.1-.948-.2-1.397-.2-1.346-.548-1.545-1.745-.847L23.335 14.604l2.044-5.784c.898-2.593.848-2.792-1.645-3.99-.05-.049-.1-.049-.15-.099-1.446-.698-1.695-.598-2.244.948l-2.343 6.582-2.843-6.233c-1.296-2.843-1.546-2.942-4.488-1.895-1.246.449-1.346.648-.797 1.845l3.141 6.93-6.981-2.791c-2.593-1.048-2.792-.998-4.089 1.495-.05.15-.15.25-.2.4-.548 1.146-.498 1.346.699 1.844l6.682 2.693-6.682 1.945c-2.693.797-2.793.897-2.344 3.69 0 .1.05.2.05.299.25 1.446.449 1.595 1.895 1.197l7.679-2.195-4.538 6.882c-1.446 2.194-1.446 2.343.499 4.138.15.15.3.3.499.45 1.047.897 1.246.847 1.994-.25l4.737-7.23.898 9.025c.25 2.643.349 2.742 2.992 2.942.249 0 .548.05.848.05 1.196.05 1.346-.1 1.196-1.297l-.897-9.025 6.233 7.43c1.496 1.795 1.496 1.795 3.64.947.399-.15.698-.299 1.147-.498 1.196-.549 1.246-.798.399-1.796l-6.682-7.978 12.765 4.139c1.895.598 1.745.648 3.191-.698.499-.449.848-.798 1.247-1.197.997-1.047.898-1.446-.499-1.895l-11.419-3.69 15.508-2.194c1.945-.299 1.845-.2 2.593-2.044.25-.599.399-1.097.549-1.646.399-1.396.15-1.695-1.297-1.496L28.97 16.998 47.32 6.377c1.945-1.147 1.895-1.097 1.696-3.241Zm-30.118 18.2c-1.097.598-2.444.25-3.042-.798-.598-1.097-.25-2.443.798-3.042 1.047-.598 2.443-.249 3.042.798a2.167 2.167 0 0 1-.798 3.042Z'
      />
    </svg>
  );
}
export default SvgKota;
