import * as React from 'react';
import type { SVGProps } from 'react';
function SvgArrowUpRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 24 24' {...props}>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M7 17 17 7m0 0H7m10 0v10'
      />
    </svg>
  );
}
export default SvgArrowUpRight;
