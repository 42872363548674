import * as React from 'react';
import type { SVGProps } from 'react';
function SvgUserX01(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 22 20' {...props}>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='m15.5 14 5 5m0-5-5 5M11 13.5H6.5c-1.396 0-2.093 0-2.661.172a4 4 0 0 0-2.667 2.667C1 16.907 1 17.604 1 19M13.5 5.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Z'
      />
    </svg>
  );
}
export default SvgUserX01;
