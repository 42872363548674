import * as React from 'react';
import type { SVGProps } from 'react';
function SvgRefreshCw05(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 24 24' {...props}>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M20.452 12.893A8.5 8.5 0 0 1 4.637 16.25l-.25-.433m-.842-4.71A8.5 8.5 0 0 1 19.36 7.75l.25.433M3.492 18.066l.732-2.732 2.732.732m10.085-8.132 2.732.732.733-2.732'
      />
    </svg>
  );
}
export default SvgRefreshCw05;
