import * as React from 'react';
import type { SVGProps } from 'react';
function SvgWallet05(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 24 24' {...props}>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M19 10H5m14 0 1.865 3.107c.418.696.627 1.044.735 1.42.096.331.134.678.112 1.023-.024.389-.152.774-.41 1.544l-.207.624c-.394 1.18-.59 1.77-.955 2.206a3 3 0 0 1-1.203.868c-.53.208-1.152.208-2.396.208h-9.08c-1.245 0-1.867 0-2.396-.208a3 3 0 0 1-1.203-.868c-.365-.436-.562-1.026-.955-2.206l-.208-.624c-.257-.77-.385-1.155-.41-1.545a3 3 0 0 1 .113-1.023c.108-.375.317-.723.735-1.42L5 10m14 0 .417-1.46c.198-.693.297-1.039.22-1.313a1 1 0 0 0-.436-.577c-.242-.15-.602-.15-1.322-.15H6.122c-.72 0-1.08 0-1.322.15a1 1 0 0 0-.435.577c-.078.274.02.62.219 1.313L5 10m7-3.5H8.465c-.52 0-1.02-.21-1.389-.586A2.019 2.019 0 0 1 6.501 4.5c0-.53.207-1.04.575-1.414a1.947 1.947 0 0 1 1.39-.586c2.75 0 3.535 4 3.535 4Zm0 0h3.536c.52 0 1.02-.21 1.388-.586.369-.375.576-.884.576-1.414 0-.53-.207-1.04-.576-1.414a1.947 1.947 0 0 0-1.389-.586c-2.75 0-3.535 4-3.535 4Z'
      />
    </svg>
  );
}
export default SvgWallet05;
