import * as React from 'react';
import type { SVGProps } from 'react';
function SvgArrowUp(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 24 24' {...props}>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M12 19V5m0 0-7 7m7-7 7 7'
      />
    </svg>
  );
}
export default SvgArrowUp;
