import * as React from 'react';
import type { SVGProps } from 'react';
function SvgFeedback(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 20 20' {...props}>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.75}
        d='M2.5 6.5c0-1.4 0-2.1.272-2.635a2.5 2.5 0 0 1 1.093-1.093C4.4 2.5 5.1 2.5 6.5 2.5h7c1.4 0 2.1 0 2.635.272a2.5 2.5 0 0 1 1.092 1.093C17.5 4.4 17.5 5.1 17.5 6.5V11c0 1.4 0 2.1-.273 2.635a2.5 2.5 0 0 1-1.092 1.092C15.6 15 14.9 15 13.5 15h-2.097c-.52 0-.78 0-1.029.051a2.5 2.5 0 0 0-.634.223c-.227.115-.43.278-.836.603l-1.988 1.59c-.346.277-.52.416-.666.416a.417.417 0 0 1-.326-.157c-.09-.114-.09-.336-.09-.78V15c-.776 0-1.163 0-1.481-.085a2.5 2.5 0 0 1-1.768-1.768c-.085-.318-.085-.705-.085-1.48V6.5Z'
      />
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.75}
        d='M9.997 6.416c-.75-.866-2-1.099-2.939-.306-.94.793-1.072 2.118-.334 3.056.457.58 1.604 1.642 2.411 2.365.296.265.444.398.622.452a.855.855 0 0 0 .48 0c.178-.054.327-.187.623-.452.807-.723 1.954-1.784 2.41-2.365a2.172 2.172 0 0 0-.333-3.056c-.956-.784-2.19-.56-2.94.306Z'
        clipRule='evenodd'
      />
    </svg>
  );
}
export default SvgFeedback;
